import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
    selector: 'app-historique',
    templateUrl: './historique.component.html',
    styleUrls: ['./historique.component.scss'],
    standalone: false
})
export class HistoriqueComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Historique du CPLC');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.CPLC);
  }
}
